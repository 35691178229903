import LoadTransition from "./LoadTransition";
import Button from "./utilities/Button";

export default function CustomAlert({errorMessage, onOk, onCancel, okText, cancelText}) {
    if (!okText) {
        okText = 'OK';
    }

    if (!cancelText) {
        cancelText = 'Annuller';
    }

    return (
        <>
            {errorMessage !== null && (
                <div className={'w-full h-full fixed top-0 left-0 bg-black/[0.6] z-[999] p-5'}>
                    <div
                        className={'relative bg-red-500 top-1/2 -translate-y-1/2 p-5 -mt-10 text-white rounded shadow text-center'}>
                        {errorMessage}
                        <div>
                            {onCancel && (
                                <Button type={'light'}
                                        className={'inline-block mr-3 mx-auto mt-3'}
                                        onClick={(e) => {
                                            onCancel();
                                        }}>
                                    {cancelText}
                                </Button>
                            )}
                            <Button type={'light'}
                                    className={'inline-block mx-auto mt-3'}
                                    onClick={(e) => {
                                        onOk();
                                    }}>
                                {okText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}