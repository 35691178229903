import React from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import {Layout} from './components/Layout';
import './custom.css';
import {AnimatePresence} from "framer-motion";
import PalletService, {PalletStatus} from "./services/PalletService";

export default function App() {
    const location = useLocation();

    // Request permissions for coordinates if not already granted
    navigator.permissions.query({name: 'geolocation'}).then(function (result) {
        if (result.state === 'granted') {
            console.log('Permission already granted');
        } else if (result.state === 'prompt') {
            console.log('Permission not granted, requesting permission...');
            navigator.geolocation.getCurrentPosition(() => {
                console.log('Permission granted');
            }, (error) => {
                console.error(error);
            });
        } else if (result.state === 'denied') {
            console.log('Permission denied');
        }
    });

    const worker = new Worker('./worker.js');
    worker.onmessage = (e) => {
        if (e.data === 'handlePalletLog') {
            PalletService.logPalletEvent('', PalletStatus.DRIVING);
            PalletService.executePalletLogQueue();
        }
    }
    
    return (
        <Layout>
            <AnimatePresence>
                <Routes location={location} key={location.pathname}>
                    {AppRoutes.map((route, index) => {
                        const {element, ...rest} = route;
                        return <Route key={index} {...rest} element={element}/>;
                    })}
                </Routes>
            </AnimatePresence>
        </Layout>
    );
}
