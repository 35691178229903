import {ApiClient} from "../apiClient";

export default class PalletService {
    static getScannedPallets() {
        const pallets = localStorage.getItem('scannedPallets');
        if (pallets) {
            return JSON.parse(pallets);
        } else {
            return [];
        }
    }

    static addScannedPallet(palletId) {
        const pallets = this.getScannedPallets();
        pallets.push(palletId);
        localStorage.setItem('scannedPallets', JSON.stringify(pallets));
    }

    static removeScannedPallet(palletId) {
        const pallets = this.getScannedPallets();
        const index = pallets.findIndex(p => p.id === palletId);
        pallets.splice(index, 1);
        localStorage.setItem('scannedPallets', JSON.stringify(pallets));
    }

    static clearScannedPallets() {
        localStorage.removeItem('scannedPallets');
    }

    static logPalletEvent(palletId, status) {
        const logEvent = (coordinates) => {
            const palletEvents = localStorage.getItem('palletEvents');
            if (palletEvents) {
                const events = JSON.parse(palletEvents);
                let highestEntryNo = 1;
                if (events.filter(i => i.palletId === palletId).length > 0) {
                    highestEntryNo = Math.max(...events.filter(i => i.palletId === palletId).map(i => i.entryNo)) + 1;
                }

                events.push({
                    palletId: palletId,
                    entryNo: highestEntryNo,
                    status: status,
                    timestamp: new Date().toISOString(),
                    latitude: coordinates.latitude,
                    longitude: coordinates.longitude,
                    driver: localStorage.getItem('driverId'),
                    vehicleCode: localStorage.getItem('vehicleId')
                });
                localStorage.setItem('palletEvents', JSON.stringify(events));
            } else {
                let entryNo = 1;
                localStorage.setItem('palletEvents', JSON.stringify([{
                    palletId: palletId,
                    entryNo: entryNo,
                    status: status,
                    timestamp: new Date().toISOString(),
                    latitude: coordinates.latitude,
                    longitude: coordinates.longitude,
                    driver: localStorage.getItem('driverId'),
                    vehicleCode: localStorage.getItem('vehicleId')
                }]));
            }
        }

        navigator.geolocation.getCurrentPosition((position) => {
            logEvent(position.coords);
        }, (error) => {
            console.error(error);
            alert('Kunne ikke finde din position. Prøv igen.');
        })
    }

    static getPalletEvents() {
        const palletEvents = localStorage.getItem('palletEvents');
        if (palletEvents) {
            return JSON.parse(palletEvents);
        } else {
            return [];
        }
    }

    static executePalletLogQueue() {
        let events = this.getPalletEvents();
        if (events.length > 0) {
            events.forEach((event) => {
                const postEvent = {
                    palletId: event.palletId,
                    entryNo: event.entryNo,
                    status: event.status,
                    timestamp: event.timestamp,
                    latitude: event.latitude,
                    longitude: event.longitude,
                    driver: event.driver,
                    vehicleCode: event.vehicleCode
                }

                ApiClient.post('/api/pallets/events', postEvent)
                    .then(response => {
                        if (response.status === 200) {
                            const palletEvents = this.getPalletEvents();
                            const index = palletEvents.findIndex(i => i.palletId === event.palletId && i.entryNo === event.entryNo);
                            palletEvents.splice(index, 1);
                            localStorage.setItem('palletEvents', JSON.stringify(palletEvents));
                        }
                    }).catch(error => {
                    throw error;
                })
            })
        }
    }
}

// Export enum with pallet statuses
export const PalletStatus = {
    LOADED: 'LOADED',
    REMOVED: 'REMOVED',
    DELIVERED_TO_CUSTOMER: 'DELIVERED_TO_CUSTOMER',
    DELIVERED_TO_DEPOT: 'DELIVERED_TO_DEPOT',
    DRIVING: 'DRIVING',
    RETURNED: 'RETURNED'
}