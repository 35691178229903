import LoadTransition from "../components/LoadTransition";
import BackButton from "../components/BackButton";
import Button from "../components/utilities/Button";
import {Container} from "../components/utilities/Container";
import {useEffect, useState} from "react";
import PalletService, {PalletStatus} from "../services/PalletService";
import {QrScanner} from "@yudiel/react-qr-scanner";
import CustomAlert from "../components/CustomAlert";
import {useNavigate} from "react-router-dom";

export default function Drive() {
    const [scannedPallets, setScannedPallets] = useState([]);
    const [scanActive, setScanActive] = useState(false);
    const [currentAction, setCurrentAction] = useState(null);
    const [alertErrorMessage, setAlertErrorMessage] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        setScannedPallets(PalletService.getScannedPallets());
    }, [])

    return (
        <LoadTransition>
            <Container>
                <CustomAlert errorMessage={alertErrorMessage} onOk={() => {
                    setAlertErrorMessage(null);
                }}/>
                {confirmDialog !== null && (
                    <CustomAlert errorMessage={confirmDialog.message} onOk={confirmDialog.onOk}
                                 onCancel={confirmDialog.onCancel}/>
                )}
                <BackButton to={'/scan-for-transport'}/>
                <div className={'text-center'}>
                    <h1 className={'text-4xl font-bold'}>Kørsel</h1>
                </div>
                {scanActive && (
                    <QrScanner onError={(e) => {
                        console.error(e);
                    }} onResult={(result) => {
                        if (alertErrorMessage !== null) {
                            return;
                        }

                        if (!result.text.startsWith('DX')) {
                            setAlertErrorMessage('QR-koden er ikke gyldig.');
                            return;
                        }

                        if (scannedPallets.filter(p => p === result.text).length <= 0) {
                            setAlertErrorMessage('Pallen er ikke scannet ind til levering.');
                            return;
                        }

                        setScanActive(false);

                        let message = '';
                        let status = null;
                        if (currentAction === 'deletePallet') {
                            message = 'Er du sikker på at du vil slette pallen?';
                            status = PalletStatus.REMOVED;
                        } else if (currentAction === 'deliverToCustomer') {
                            message = 'Er du sikker på at du vil levere pallen til kunden?';
                            status = PalletStatus.DELIVERED_TO_CUSTOMER;
                        } else if (currentAction === 'deliverToDepot') {
                            message = 'Er du sikker på at du vil levere pallen til depotet?';
                            status = PalletStatus.DELIVERED_TO_DEPOT;
                        }

                        setConfirmDialog({
                            message: message,
                            onOk: () => {
                                PalletService.removeScannedPallet(result.text);
                                PalletService.logPalletEvent(result.text, status);
                                setScannedPallets(PalletService.getScannedPallets());
                                setConfirmDialog(null);
                            },
                            onCancel: () => {
                                setConfirmDialog(null);
                            }
                        });
                        return;
                    }}/>
                )}
                <ScanButton buttonText={'Scan palle ud til kunde'} onClick={() => {
                    setCurrentAction('deliverToCustomer');
                    setScanActive(true);
                }}/>
                <ScanButton buttonText={'Scan palle ud til depot'} onClick={() => {
                    setCurrentAction('deliverToDepot');
                    setScanActive(true);
                }}/>
                <ScanButton buttonText={'Slet palle'} onClick={() => {
                    setCurrentAction('deletePallet');
                    setScanActive(true);
                }}/>
                <ScanButton buttonText={'Stop kørsel'} onClick={() => {
                    setCurrentAction('stopDriving');
                    setConfirmDialog({
                        message: 'Er du sikker på at du vil stoppe kørslen?',
                        onOk: () => {
                            scannedPallets.forEach(pallet => {
                                PalletService.logPalletEvent(pallet, PalletStatus.RETURNED);
                            })
                            
                            PalletService.clearScannedPallets();
                            setScannedPallets(PalletService.getScannedPallets());
                            setConfirmDialog(null);
                            
                            navigate('/dashboard');
                        },
                        onCancel: () => {
                            setConfirmDialog(null);
                        }
                    })
                }}/>
                <div className={'text-center mt-5'}>
                    Paller tilbage
                    <div className={'font-bold'}>
                        {scannedPallets.length}
                    </div>
                </div>
            </Container>
        </LoadTransition>
    )
}

function ScanButton({buttonText, onClick}) {
    return (
        <div className={'text-center mt-5'}>
            <Button className={'block w-full'} onClick={(e) => {
                onClick();
            }}>{buttonText}</Button>
        </div>
    )
}