import Button from "../components/utilities/Button";
import {Container} from "../components/utilities/Container";
import BackButton from "../components/BackButton";
import PalletService from "../services/PalletService";

export default function Developer() {
    return (
        <>
            <Container>
                <BackButton to={'/dashboard'} />
                <h1 className={'text-center font-bold text-xl mb-5'}>Udvikler</h1>
                <Button className={'block w-full'} type={'black'} onClick={(e) => {
                    PalletService.executePalletLogQueue();
                }}>Kør palle log</Button>
            </Container>
        </>
    )
}