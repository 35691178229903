import BackButton from "../components/BackButton";
import {QrScanner, useMediaDevices} from "@yudiel/react-qr-scanner";
import {motion} from "framer-motion";
import LoadTransition from "../components/LoadTransition";
import {useEffect, useState} from "react";
import VehicleSelector from "../components/VehicleSelector";
import CustomAlert from "../components/CustomAlert";
import PalletService, {PalletStatus} from "../services/PalletService";
import Button from "../components/utilities/Button";
import {Container} from "../components/utilities/Container";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-solid-svg-icons";
import {useNavigate} from "react-router-dom";

export default function ScanPalletsForTransport({}) {
    const [currentVehicle, setCurrentVehicle] = useState(null);
    const [alertErrorMessage, setAlertErrorMessage] = useState(null);
    const [scanActive, setScanActive] = useState(true);
    const [scannedPallets, setScannedPallets] = useState([]);
    const [palletForDeletion, setPalletForDeletion] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const vehicleId = localStorage.getItem('vehicleId');
        const vehicles = localStorage.getItem('vehicles');

        if (vehicleId) {
            setCurrentVehicle(JSON.parse(vehicles).find(vehicle => vehicle.code === vehicleId));
            setScannedPallets(PalletService.getScannedPallets());
        }
    }, [])

    return (
        <>
            <LoadTransition>
                <BackButton to={'/dashboard'}/>
                {currentVehicle !== null && (
                    <>
                        {palletForDeletion !== null && (
                            <CustomAlert 
                                onOk={() => {
                                    PalletService.removeScannedPallet(palletForDeletion);
                                    PalletService.logPalletEvent(palletForDeletion, PalletStatus.REMOVED);
                                    setScannedPallets(PalletService.getScannedPallets());
                                    setPalletForDeletion(null);
                                }}
                                onCancel={() => {
                                    setPalletForDeletion(null);
                                }}
                                cancelText={'Fortryd'}
                                okText={'Slet palle'}
                                errorMessage={'Er du sikker på at du vil slette pallen?'}></CustomAlert>
                        )}
                        <CustomAlert errorMessage={alertErrorMessage} onOk={() => {
                            setAlertErrorMessage(null);
                        }}/>
                        <div className={'text-center mb-3'}>Nuværende bil: <span
                            className={'underline cursor-pointer'}
                            onClick={(e) => {
                                e.preventDefault();
                                setCurrentVehicle(null);
                            }}>{currentVehicle.description} ({currentVehicle.code})</span></div>
                        {!scanActive && (
                            <div className={'text-center'}>
                                <Button type={'primary'} onClick={(e) => {
                                    e.preventDefault();
                                    setScanActive(true);
                                }}>Scan palle</Button>
                            </div>
                        )}
                        {scanActive && (
                            <QrScanner onError={(err) => console.log(err)} onResult={(result) => {
                                if (alertErrorMessage !== null) {
                                    return;
                                }

                                if (result.text === currentVehicle.code) {
                                    // Ensures that the vehicle QR code is only scanned once
                                    return;
                                }

                                if (!result.text.startsWith('DX')) {
                                    setAlertErrorMessage('QR-koden er ikke gyldig.');
                                    return;
                                }

                                let scannedPallets = PalletService.getScannedPallets();
                                if (scannedPallets.filter(p => p === result.text).length > 0) {
                                    setAlertErrorMessage('Pallen er allerede scannet.');
                                    return;
                                }

                                setScanActive(false);

                                const palletId = result.text;

                                PalletService.addScannedPallet(palletId);
                                PalletService.logPalletEvent(palletId, PalletStatus.LOADED);
                                setScannedPallets(PalletService.getScannedPallets());
                            }}/>
                        )}
                        {scannedPallets.length > 0 && (
                            <>
                                <Container>
                                    <div className={'text-center font-bold text-lg mt-3'}>Scannede paller</div>
                                    {scannedPallets.map((palletId, index) => {
                                        return (
                                            <div key={index}
                                                 className={
                                                     'bg-white rounded-lg shadow p-1 cursor-pointer text-center mt-3 relative'}>
                                                {palletId}
                                                <FontAwesomeIcon icon={faTimes}
                                                                 className={'absolute right-3 top-2 text-red-500 text-lg'}
                                                                 onClick={(e) => {
                                                                     e.preventDefault();
                                                                     setPalletForDeletion(palletId)
                                                                 }}
                                                />
                                            </div>
                                        )
                                    })}
                                    <div className={'text-center mt-5'}>
                                        <Button type={'black'}
                                                onClick={(e) => {
                                                    navigate('/drive');
                                                }}>Start kørsel</Button>
                                    </div>
                                </Container>
                            </>
                        )}
                    </>
                )}
                {currentVehicle === null && (
                    <VehicleSelector onSelect={(vehicle) => {
                        localStorage.setItem('vehicleId', vehicle.code);
                        setCurrentVehicle(vehicle);
                    }}/>
                )}
            </LoadTransition>
        </>
    )
}