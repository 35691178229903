import {useEffect, useState} from "react";
import {ApiClient} from "../apiClient";
import {Container} from "./utilities/Container";
import {motion} from "framer-motion";
import {QrScanner} from "@yudiel/react-qr-scanner";

export default function VehicleSelector({onSelect}) {
    const [vehicles, setVehicles] = useState([]);

    useEffect(() => {
        async function getVehicles() {
            const response = await ApiClient.get('/api/vehicles');
            if (response.status === 200) {
                const json = await response.json();
                setVehicles(json);

                localStorage.setItem('vehicles', JSON.stringify(json));
            } else {
                console.error(await response.text())

                const vehicles = localStorage.getItem('vehicles');
                if (vehicles) {
                    setVehicles(JSON.parse(vehicles));
                }
            }
        }

        getVehicles().catch(error => {
            console.error(error);
        });

    }, [])

    return (
        <Container>
            {window.globalSettings.vehicleSelector === 'list' && (
                <>
                    <h1 className={'text-center text-3xl font-bold mb-5'}>Vælg køretøj</h1>
                    <div className={'grid grid-cols-1 gap-5'}>
                        {vehicles.map((vehicle, index) => (
                            <motion.div
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                transition={{duration: 0.8}}
                                key={vehicle.code}>
                                <div className={'bg-white rounded-lg shadow p-5 cursor-pointer'}
                                     onClick={(e) => {
                                         onSelect(vehicle)
                                     }}>
                                    <div className={'flex items-center justify-center text-center'}>
                                        <div>
                                            {vehicle.description}
                                            <br/>
                                            {vehicle.code}
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </>
            )}
            {window.globalSettings.vehicleSelector === 'scanQr' && (
                <>
                    <h1 className={'text-center text-3xl font-bold mb-5'}>Scan køretøj</h1>
                    <QrScanner
                        onResult={(result) => {
                            const vehicle = vehicles.find(vehicle => vehicle.code === result.text);
                            if (vehicle) {
                                onSelect(vehicle);
                            }
                        }}
                        onError={(err) => {
                            console.error(err, 'error');
                        }}/>
                </>
            )}
        </Container>
    )
}